import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
//import AppFooter from "../components/AppFooter";
import { PaystackButton } from "react-paystack"
import axios from "axios";
//import Logo from "../components/Logo";
import { billValidate } from "src/remote/bill";
import SuccessImage from "src/components/Success";
import FailedImage from "src/components/Failed";
import logo from '../../assets/images/lagos_logo.jpeg'
import logotech from '../../assets/images/full_logo.png'
import lirsLogo from '../../assets/images/lris_lhd8ny.gif'
import { isAuthenticated } from "src/remote/auth";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";


const EtaxPayment = () => {
    let navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true)
    const [bill, setBill] = useState(null)
    const [status, setStatus] = useState(null)
    const [error, setError] = useState("")
    const [errorEmail, setErrorEmail] = useState("")
    const [email, setEmail] = useState("")
    const today = new Date()
    const [phone, setPhone] = useState("")
    const [surcharge, setSurcharge] = useState(60)
    const [errorPhone, setErrorPhone] = useState("")
    const [errorAmount, setErrorAmount] = useState("")
    const [amountToPay, setAmountToPay] = useState(null)
    const [receiptUrl, setReceiptUrl] = useState("");
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const [searchParams, setSearchParams] = useSearchParams();
    const [metadata, setMetadata] = useState({})

    let surchargeOut = 60;


    const [componentProps, setComponentProps] = useState({
        email: "",
        amount: "",
        metadata: {
            name: "",
            phone: "",
        },
        text: "Pay with Paystack",
        onSuccess: () =>
            alert("Thanks for doing business with us! Come back soon!!"),
        onClose: () => alert("Wait! Don't leave :("),
    })

    const [componentPropsFlutterWave, setComponentPropsFlutterWave] = useState({
        tx_ref: Date.now(),
        amount: 100,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: '',
            phone_number: '',
            name: '',
        },
        meta: {},
        customizations: {
            title: 'my Payment Title',
            description: 'Payment for items in cart',
            logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
    })

    const handleChange = (val) => {
        let value = "";
        if (val) {
            value = val.replace(/,/gi, '')
        } else {
            value = amountToPay.replace(/,/gi, '')
        }

        const getCharge = getSurcharge(value)

        value = parseFloat(value).toFixed(2) + ""

        setComponentPropsFlutterWave({
            tx_ref: 'FLW' + phone.replace('+', '') + new Date().toISOString(),
            amount: parseFloat(value) + getCharge,
            currency: 'NGN',
            payment_options: 'card,mobilemoney,ussd',
            customer: {
                email: email,
                phone_number: "+234" + phone,
                name: bill.PayerName,
            },
            meta: {
                phone: "+234" + phone,
                email: email,
                TransactionType: 'PAY_BILL',
                bill_reference: params.billReference,
                amount: parseFloat(value),
                agencyCode: bill.AgencyCode,
                creditAccount: bill.CreditAccount,
            },
            customizations: {
                title: 'Techpay',
                description: `Bill payment of ₦ ${Intl.NumberFormat('en-US').format(parseFloat(value))} for bill with reference ${params.billReference}`,
                //logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
            },
        })

        const transactionFee = Math.round(parseFloat(value.replace('.', '')) * 0.002) > 24000 ? 24000 : Math.round(parseFloat(value.replace('.', '')) * 0.002);

        //console.log(transactionFee)

        setMetadata({
            phone: "+234" + phone,
            email:email,
            surcharge: getCharge,
            transactionType: 'PAY_BILL',
            bill_reference: params.billReference,
            amount: amountToPay,
            agencyCode: bill.AgencyCode,
            creditAccount: bill.CreditAccount,
            custom_fields: [
                {
                    display_name: "Bill Reference",
                    variable_name: "Bill Reference",
                    value: params.billReference
                },
                {
                    display_name: "Payer",
                    variable_name: "Payer",
                    value: bill.PayerName
                }
            ]
        })

        setComponentProps({
            email: email,
            amount: Math.round(parseFloat(value.replace('.', ''))) + Math.round(parseFloat(value.replace('.', '')) * 0.002),
            transaction_charge: Math.round(parseFloat(value.replace('.', '')) * 0.002),
            label: bill.PayerName,
            metadata: {
                phone: "+234" + phone,
                transactionType: 'PAY_BILL',
                surcharge: getCharge * 0.01,
                bill_reference: params.billReference,
                bill_type: params.billType.toLowerCase(),
                bills: bill.bills,
                amount: amountToPay,
                agencyCode: bill.AgencyCode,
                creditAccount: bill.CreditAccount,
                custom_fields: [
                    {
                        display_name: "Bill Reference",
                        variable_name: "Bill Reference",
                        value: params.billReference
                    },
                    {
                        display_name: "Payer",
                        variable_name: "Payer",
                        value: bill.PayerName
                    }
                ]
            },
            subaccount: bill.subaccount_code,

            text: "Pay with Paystack",
            onSuccess: (data) => {
                console.log(data)
                setStatus("success")
                setLoading(true)
                setTimeout(function () {
                    axios.get(data.redirecturl).then(response => {
                        console.log("1", response)
                        console.log("1", response.data)
                        if (response.data.message === 'successful') {
                            setLoading(false)
                            setReceiptUrl(response.data.data)
                            window.open(response.data.data, "_blank");
                            window.close();
                            //window.replace(response.data.data.receipt_url);
                            //navigate(response.data.data.receipt_url)
                        }
                    })
                }, 10000)
            },
            onClose: () => {
                alert("Wait! Don't leave :(");
                setStatus("failed")
            },
        })
    }

    const sarePayment = () => {
        let value = amountToPay.replace(/,/gi, '')
        value = parseFloat(value).toFixed(2) + ""
        const fee = Math.round(parseFloat(value) * 0.01)
        const transactionFee = fee > 1200 ? 1200 : fee < 60 ? 60 : fee


        Sarepay.initialize({
            key: process.env.REACT_APP_SAREPAY_PUBLIC,
            token: 'gSWZMZar1eyuAVXIwjT4j6Pbd8M2M4zJ',
            amount: parseInt(amountToPay.replace(/,/gi, '')) + transactionFee,
            currency: "NGN",
            feeBearer: "merchant",
            customer: { name: bill.PayerName, email: email },
            containerId: "payment-container",
            metadata: metadata,
            reference: 'SP' + phone.replace('+', '') + new Date().toISOString(),
            onClose: function () {
                //console.log("😩, you are gone");
            },
            onSuccess: function (data) {
                //console.log(data);
                setStatus("success")
                setLoading(true)
                setTimeout(function () {
                    axios.get(`${process.env.REACT_APP_URL}/transaction/getArrReceipt?reference=${data.reference}`).then(response => {
                        console.log("1", response)
                        console.log("1", response.data)
                        if (response.data.message === 'successful') {
                            setLoading(false)
                            setReceiptUrl(response.data.data)
                            window.open(response.data.data, "_blank");
                            window.close();
                            //window.replace(response.data.data.receipt_url);
                            //navigate(response.data.data.receipt_url)
                        }
                    })
                }, 10000)
            },
            onFailed: function (data) {
                console.log(data);
            },
        });

    }

    const validate = () => {
        if (reg.test(email) && phone.length > 9 && error.length == 0 && errorAmount.length == 0 && errorEmail.length == 0 && errorPhone.length == 0) {
            return true
        }
        return false
    }

    useEffect(() => {
        billValidate({ bill_type: params.billType, bill_ref: params.billReference }).then(response => {
            if (response.message === "successful") {
                if (params.billType.toLowerCase() === 'harmonized' && response.data.Status === "SUCCESS") {
                    if (response.data.bulkBill && response.data.bulkBill.TotalDue) {
                        setBill({
                            AmountDue: response.data.bulkBill.TotalDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            PayerName: response.data.bulkBill.PayerName,
                            bills: response.data.bulkBill.Webguid
                        })
                        setAmountToPay(response.data.bulkBill.TotalDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                        setSurcharge(getSurcharge(response.data.bulkBill.TotalDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/,/gi, '')))
                    }
                } else if (response.data.Status === "SUCCESS" && response.data.AgencyCode) {
                    if (response.data.CreditAccount === "" || response.data.CreditAccount === null) {
                        setError("Transaction not authorized on this platform.")
                        setBill(null)
                    } else {
                        setBill(response.data)
                        setAmountToPay(response.data.AmountDue)
                        setSurcharge(getSurcharge(response.data.AmountDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/,/gi, '')))
                    }
                } else {
                    setError(response.data.statusMessage)
                    setStatus("failed")
                }
            } else {
                setError(response.data.statusMessage)
                setStatus("failed")
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            setStatus("failed")
        })
    }, [])

    const changeAmountToPay = (text) => {
        let value = text.target.value.replace(/,/gi, '')

        //value = parseFloat(value)/100;
        setAmountToPay(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        if (parseFloat(value) > parseFloat(bill.AmountDue.replace(/,/gi, ''))) {
            setErrorAmount("Amount cannot be more than amount due")
        } else {
            setErrorAmount("")
        }
        handleChange(value);

        const transactionFee = getSurcharge(value) > 1200 ? 1200 : getSurcharge(value) < 60 ? 60 : getSurcharge(value)


        setSurcharge(transactionFee)

    }

    const getSurcharge = (value) => {
        let charge = parseFloat(value.replace('.', '')) * 0.01;
        if (value === "") {
            return 0
        } else if (charge > 1200) {
            surchargeOut = 1200;
            return 1200
        } else {
            surchargeOut = charge;
            return charge
        }
    }

    const changeEmail = (text) => {
        if (!reg.test(text.target.value)) {
            setErrorEmail("Please provide a valid email")
        } else {
            setErrorEmail("")
        }
        setEmail(text.target.value)
        handleChange(null);
    }

    const changePhone = (text) => {
        if (text.target.value.length < 10) {
            setErrorPhone('Please provide a valid phone number')
        } else {
            setErrorPhone("")
        }
        setPhone(text.target.value)
        handleChange(null);
    }


    return <>
        <nav className="navbar navbar-light">
            <a className="navbar-brand" href="#">
                <img src={logo} style={{ marginLeft: 100 }} height="80" alt="" />
            </a>

            <div className="d-flex">
                <img src={lirsLogo} style={{ marginRight: 100 }} height="80" alt="" />
            </div>
        </nav>

        <CircleSpinnerOverlay
            loading={loading}
            color="green"
            overlayColor="rgba(0,153,255,0.2)"
        />

        {!bill && status === "failed" && <section className="features-area pt-4 bg-eeeff1">
            <div style={{ maxWidth: 600 }} className="container">
                <div className="bg-light card shadow-sm rounded p-3 p-sm-4 mb-4">
                    <center>
                        <FailedImage />
                        {error && <h5 className="mt-4">{error}</h5>}
                    </center>
                </div>
            </div>
        </section>}

        {bill && <section className="features-area pt-4 bg-eeeff1">
            <div style={{ maxWidth: 600 }} className="container">

                <div className="bg-light card shadow-sm rounded p-3 p-sm-4 mb-4">

                    {status === "success" ?
                        <>
                            {searchParams.get("returnUrl") && <a style={{ color: 'black', padding: 20 }} className='p-2' href={searchParams.get("returnUrl")}> <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to previous page</a>}
                            <center>
                                <SuccessImage />

                                {receiptUrl && params.billType.toLowerCase() !== 'harmonized' && searchParams.get("returnUrl") ?

                                    <a className='link' target="_blank" href={receiptUrl}>Click here for your receipt</a>

                                    :
                                    <a className='link' onClick={() => {
                                        if (isAuthenticated()) {
                                            navigate('/dashboard', { replace: true })
                                        } else {
                                            navigate('/', { replace: true })
                                        }
                                    }} target="_blank" href={`${receiptUrl}`}>Click here for your receipt</a>
                                }


                                {receiptUrl && params.billType.toLowerCase() === 'harmonized' &&
                                    receiptUrl.map(item => <><a className='link' target="_blank" href={`${item}`}>Click here for your receipt</a> <br /></>)

                                }


                            </center> </> : status === "failed" ? <FailedImage /> :

                            <>
                                <img src={logotech} width="150" height="40" alt="" />
                                <hr />
                                <div className="row">
                                    <h5 className="col-sm-3 text-3 " style={{ color: 'black', marginLeft: '3.2%' }}>Amount Due
                                    </h5>
                                    <h5 className="col-sm-3 offset-1" style={{ width: 200 }}>₦ {bill.AmountDue}
                                    </h5>
                                </div>
                                <div className="container">
                                    <table className="table table-borderless mt-3">
                                        <tbody>
                                            <tr>
                                                <td className="text-muted" style={{ width: '30%' }}>Amount to pay
                                                </td>
                                                <td style={{ width: '40%' }}>
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="inputGroup-sizing-sm">₦
                                                            </span>
                                                        </div>
                                                        {params.billType.toLowerCase() !== 'harmonized' ?

                                                            <input type="text" className="form-control" onChange={changeAmountToPay} value={amountToPay} />
                                                            :
                                                            <input type="text" className="form-control" readOnly value={amountToPay} />
                                                        }

                                                        <div className="help-block with-errors" style={{ color: 'red', paddingTop: 10 }}>{errorAmount}</div>
                                                    </div>
                                                    <div className="text-danger">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Surcharge
                                                </td>
                                                <td>₦ {surcharge}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Bill Reference
                                                </td>
                                                <td>{params.billReference}
                                                </td>
                                            </tr>
                                            {
                                                params.billType.toLowerCase() !== 'harmonized' && <>

                                                    <tr>
                                                        <td className="text-muted">Agency
                                                        </td>
                                                        <td>{bill.AgencyName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-muted">Revenue
                                                        </td>
                                                        <td>{bill.RevName}
                                                        </td>
                                                    </tr>

                                                </>
                                            }

                                            <tr>

                                                <td className="text-muted">Bill Type
                                                </td>
                                                <td>{params.billType}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Date
                                                </td>
                                                <td>{today.toString().split("GMT")[0]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Customer Name
                                                </td>
                                                <td>{bill.PayerName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Email</td>
                                                <td><div className="form-group input-group input-group-sm">
                                                    <input type="email" style={{ width: '100%' }} className="form-control form-control-sm" onChange={changeEmail} placeholder="Enter your Email Address" value={email} />
                                                    <div className="help-block with-errors" style={{ color: 'red', marginTop: 10 }}>{errorEmail}</div>
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Phone Number
                                                </td>
                                                <td>
                                                    <div className="form-group">
                                                        <div className="input-group input-group-sm mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="inputGroup-sizing-sm">+234
                                                                </span>
                                                            </div>
                                                            <input className="form-control" maxLength={10} style={{ width: '75%' }} placeholder="8000000000" onChange={changePhone} type="tel" value={phone} />
                                                            <div className="help-block with-errors" style={{ color: 'red', paddingTop: 10 }}>{errorPhone}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>


                    }
                    {status == null && validate() && <>
                        <button className="btn btn-primary m-2" style={{ /* backgroundColor: 'purple',*/ color: 'white' }} onClick={sarePayment} >Pay with Sarepay</button>
                        {/* <PaystackButton publicKey={process.env.REACT_APP_PAYSTACK_PUBLIC} className="btn btn-primary m-2" {...componentProps} />
                        <FlutterWaveButton
                            className="btn btn-warning m-2"
                            onClose={() => {
                                alert("Wait! Don't leave :(");
                                setStatus("failed")
                            }}
                            callback={data => {
                                console.log(data)
                                if (data.status === 'successful') {
                                    axios.get(`${process.env.REACT_APP_API_URL}/flutterwave/verify_transaction?id=${data.transaction_id}`).then(response => {
                                        console.log(response)
                                        if (response.data.message === 'successful') {
                                            setStatus("success")
                                            setLoading(false)
                                            setReceiptUrl(response.data.data)
                                            window.open(response.data.data, "_blank");
                                            window.close();
                                            closePaymentModal();
                                        }
                                    }).catch(error => {
                                        console.log(error)
                                    })
                                }
                            }}
                            public_key={process.env.REACT_APP_FLUTTERWAVE_PUBLIC} text='Pay with Flutterwave!' {...componentPropsFlutterWave} /> */}

                    </>}
                </div>

                {/* <center>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Confirm Details</h5>
                            <hr />
                            <div className="row mt-5"><h5 className="col-sm-3 text-muted offset-6 ">Amount Due</h5><h5 className="col-sm-3 font-weight-500">₦ {bill.AmountDue}</h5></div>
                            <div className="container"><table className="table table-borderless mt-5"><tbody><tr><td className="text-muted" style={{width: '30%'}}>Amount</td><td style="width: 40%;"><div className="input-group input-group-sm mb-3"><div className="input-group-prepend"><span className="input-group-text" id="inputGroup-sizing-sm">₦</span></div><input type="text" className="form-control" value="1,100.00"></div><div className="text-danger"></div></td></tr><tr><td className="text-muted">Biller</td><td>Lagos</td></tr><tr><td className="text-muted">Bill Item</td><td>WebGUID</td></tr><tr><td className="text-muted">Payment Reference</td><td>E9986E64B7A5467A960907202210214E</td></tr><tr><td className="text-muted">Customer Name</td><td>STEPHEN ERAGBARE OMOARUKHE</td></tr><tr><td className="text-muted">Surcharge</td><td>₦70.00</td></tr><tr><td className="text-muted">Email</td><td><div className="form-group"><input type="email" className="form-control form-control-sm" placeholder="Enter your Email Address" value=""></div></td></tr><tr><td className="text-muted">Phone Number</td><td><div className="form-group"><div className=" react-tel-input"><div className="special-label">Phone</div><input className="form-control form-control-sm form-control" placeholder="1 (702) 123-4567" type="tel" value="+234"><div className=" flag-dropdown"><div className="selected-flag" title="Nigeria: + 234" tabIndex="0" role="button" aria-haspopup="listbox"><div className="flag ng"><div className="arrow"></div></div></div></div></div></div></td></tr></tbody></table></div>
                        </div>
                    </div>
                </center> */}
            </div>
        </section>}


    </>
}

export default EtaxPayment;