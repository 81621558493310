import { isAuthenticated } from "./auth";
const API =  process.env.REACT_APP_API_URL;

export const billValidate = (body) => {
    return fetch(`${API}/bill/search`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}

export const transactionHistory = () => {
    return fetch(`${API}/transaction/history`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${isAuthenticated()}`,
        },
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}