import { logout } from "./auth";

export const handleResponse = (response) => {
  if (!response.ok) {
    if (response.status === 401) {
      logout();
    }
  } else {
    return response;
  }
};